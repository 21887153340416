import { graphql, navigate } from "gatsby";
import React, { useMemo, useRef } from "react";
import { Element, scroller } from "react-scroll";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import ResortStyles from "../styles/ResortTempleteStyles";
import Amenities from "../components/Resort/Amenities";
import Activities from "../components/Resort/Activities";
import Spa from "../components/Resort/Spa";
import Accomodation from "../components/Resort/Accomodation";
import {
  toPlainText,
  getVillaUrl,
  getResortUrl,
  filterActiveContent,
} from "../lib/helpers";
import Highlights from "../components/Resort/Highlights";
import Restaurants from "../components/Villa/Restaurants";
import {
  Overlay,
  Banner,
  GatsbySanityImage,
  PricingProvider,
  StickyNavBar,
} from "../components";
import {
  LIGHT_COLOR,
  DEFAULT_NAVBAR_WITH_BOTTOM_LINK,
  ACCOMODATION,
  OVERVIEW,
  HIGhLIGHTS,
  DINE,
  SPA,
  ACTIVITIES,
  GLOBAL,
  RESORT_MODE,
} from "../constants";
import { useNavBar, usePageSectionsRef } from "../hooks";
import {
  AccommodationHighlightsWrapper,
  RecommendedContentWrapper,
} from "./elements";

export const query = graphql`
  query ResortTemplateQuery($id: String!) {
    resort: sanityResort(_id: { eq: $id }) {
      _id
      _rawDescription(resolveReferences: { maxDepth: 10 })
      name
      shortName
      locationAtoll
      locationFull
      numberOfBars
      numberOfRestaurants
      numberOfRooms
      roomVoltage
      timeToAirport
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.33)
        }
        alt
      }
      resortTransferType {
        transferType
      }

      villas {
        _id
        name
        active
        uniqueCode
        price
        priceOnRequest
        imageThumb {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        resort {
          name
          shortName
        }
        numrooms
        roomFeatures {
          features {
            _key
            _rawDescription
            title
          }
        }
        sizeSqm

        villaPoolTypes {
          poolType
        }

        maxOccupancy {
          option
          number
        }
      }

      restaurants {
        name
        active
        alternateName
        description
        imageThumb {
          asset {
            gatsbyImageData(aspectRatio: 1, placeholder: BLURRED, fit: FILLMAX)
          }
          alt
        }
      }

      spa {
        id
        description
        name
        active
        imageWeb {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
          alt
        }
        imageThumb {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
          alt
        }
      }

      activities {
        name
        active
        imageThumb {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
          alt
        }
      }
      reviews {
        name
        description
      }
      secondImage {
        ...SanityImage
        alt
      }
      highlights {
        name
        active
        description
        imageThumb {
          asset {
            gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
          }
          alt
        }
      }
      faq {
        name
        description
        faqQuestionsAnswers {
          # _id
          answer
          question
        }
      }
      recommendedResorts {
        active
        content {
          name
          shortName
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
            alt
          }
        }
      }
      highlightsBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
      dineBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
      activitiesBanner {
        active
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }

          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }

          alt
        }
        descriptionShort
        bannerSize
      }
    }
    featuredSpa: sanitySpa(
      resort: { _id: { eq: $id } }
      spaFeatured: { eq: true }
    ) {
      id
      description
      name
      imageWeb {
        ...SanityImage
        alt
      }
      imageThumb {
        ...SanityImage
        alt
      }
    }
  }
`;

const pageSections = [
  { name: OVERVIEW, hasSubNav: false },
  {
    name: ACCOMODATION,
    hasSubNav: true,
    visibleOnMobile: true,
  },
  { name: HIGhLIGHTS, hasSubNav: false },
  { name: DINE, hasSubNav: false },
  { name: SPA, hasSubNav: false },
  { name: ACTIVITIES, hasSubNav: false },
];

const ResortTemplate = (props) => {
  const redirectedFrom = props?.location?.state?.redirectedFrom;
  const currentSlideIndex_ = props?.location?.state?.currentSlideIndex;
  const { data, errors } = props;
  const resort = data && data.resort;
  const spas = filterActiveContent(resort?.spa);
  const villas = resort?.villas?.length ? resort?.villas : [];
  const highlights = filterActiveContent(resort?.highlights);
  const restaurants = filterActiveContent(data?.resort?.restaurants);
  const activities = filterActiveContent(resort?.activities);
  const dineBanner = filterActiveContent(resort?.dineBanner);
  const highlightsBanner = filterActiveContent(resort?.highlightsBanner);
  const activitiesBanner = filterActiveContent(resort?.activitiesBanner);
  const recommendedResorts = resort?.recommendedResorts?.length
    ? filterActiveContent(resort?.recommendedResorts)
        ?.map(({ content }) => {
          return content;
        })
        .flat()
    : [];

  const resortVillas = useMemo(() => {
    return (
      villas?.length &&
      villas?.map(({ name, uniqueCode, imageThumb }, index) => {
        return {
          className: index === 0 ? "page-title" : "",
          content: { name, uniqueCode },
          thumbImage: imageThumb,
          onClick: () => {
            const villaUrl = getVillaUrl({
              name,
              resortName: resort?.shortName,
            });
            navigate(villaUrl);
          },
        };
      })
    );
  }, [resort?.name]);

  // @TODO create seperate hook for this for clean code
  const pageSections_ = useMemo(() => {
    return [
      {
        name: resort?.shortName,
        visibleOnMobile: true,
        onClick: () => {
          const url = getResortUrl({ shortName: resort?.shortName });
          navigate(url);
        },
      },
      ...pageSections,
    ].map((section) => {
      if (section.name === ACCOMODATION) {
        return {
          ...section,
          content: resortVillas,
        };
      }
      return section;
    });
  }, []);

  const heroRef = useRef();
  const { setPageName, setNavLinks, resetValues, setHeroRef } = useNavBar();
  const { navLinks } = usePageSectionsRef(pageSections_);

  React.useEffect(() => {
    setPageName(DEFAULT_NAVBAR_WITH_BOTTOM_LINK);
    setNavLinks(navLinks);
    setHeroRef(heroRef);
  }, [navLinks?.length]);

  React.useEffect(() => {
    return () => {
      resetValues();
    };
  }, []);

  React.useEffect(() => {
    if (redirectedFrom) {
      scroller.scrollTo(ACCOMODATION);
    }
  }, [redirectedFrom]);

  const windowGlobal = typeof window !== "undefined";
  const {
    name,
    locationAtoll,
    _rawDescription,
    numberOfBars,
    numberOfRestaurants,
    numberOfRooms,
    resortTransferType,
    timeToAirport,
    image,
  } = resort;

  const heroTextClass =
    resort.image.colorType === LIGHT_COLOR ? "overlay-background" : undefined;

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {resort && (
        <SEO
          title={resort.name || "Untitled"}
          description={toPlainText(resort._rawDescription)}
          image={resort.image}
        />
      )}
      <Container>
        <ResortStyles>
          {image && (
            <div className={`resort__image ${heroTextClass}`} ref={heroRef}>
              <Overlay className="hero-overlay" />
              {image && image.asset && (
                <GatsbySanityImage
                  gatsbyImage={image?.asset?.gatsbyImageData}
                />
              )}
              <div
                className="text disappear-on-scroll"
                data-aos="zoom-out-up"
                data-aos-delay="50"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
              >
                <p className="atoll_title">{locationAtoll}</p>
                <h1 className="title_res resort_heading_title">{name}</h1>
              </div>
            </div>
          )}
          <Element name={OVERVIEW}>
            <Amenities
              locationAtoll={locationAtoll}
              numberOfBars={numberOfBars}
              numberOfRestaurants={numberOfRestaurants}
              numberOfRooms={numberOfRooms}
              resortTransferType={resortTransferType}
              timeToAirport={timeToAirport}
              _rawDescription={_rawDescription}
              title="ISLAND OVERVIEW"
            />
          </Element>
          <AccommodationHighlightsWrapper name={ACCOMODATION}>
            <Accomodation
              // elementRef={elementRef}
              id="accomodation"
              title="Accomodation"
              items={villas}
              currentSlideIndex={currentSlideIndex_}
              carouselProps={{ slidesToShow: 1 }}
            />
          </AccommodationHighlightsWrapper>
          <Highlights highlights={highlights} />
          <Banner banner={highlightsBanner} isGatsbyImage />
          <Restaurants restaurants={restaurants} />
          <Banner banner={dineBanner} isGatsbyImage />
          {spas?.length ? (
            <div
              id={SPA}
              speed={1000}
              className="resort__spas"
              slidesToShow={1}
              cellSpacing={0}
              vertical={windowGlobal && window.innerWidth < 806}
            >
              {spas.map((spa) => (
                <Spa spa={spa} key={spa.name} />
              ))}
            </div>
          ) : null}
          <Activities
            name={ACTIVITIES}
            activities={activities}
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          />
          <Banner banner={activitiesBanner} isGatsbyImage />
          <RecommendedContentWrapper>
            <Accomodation
              id="recommendedResorts"
              title="You may also like"
              isResort
              isRecommended
              items={recommendedResorts}
              currentSlideIndex={currentSlideIndex_}
              carouselProps={{ slidesToShow: 3, centerPadding: "10px" }}
            />
          </RecommendedContentWrapper>
        </ResortStyles>
      </Container>
      <PricingProvider>
        <StickyNavBar
          pricingMode={RESORT_MODE}
          defaultValues={{
            defaultResortId: resort?._id,
            rooms: villas,
          }}
        />
      </PricingProvider>
    </Layout>
  );
};

export default ResortTemplate;
